@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");

body {
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    color: #5b626b;
    padding-top: 5rem;   
    background: #f5f5f5 !important; 
}

a {
  color: #707070 !important;
}
a:hover {
  color: #7a6fbe !important;
}

/* ==============
  Top
===================*/
.bg-top {
  background:#2a3142 !important;
}

.table .align-middle td {
    vertical-align: middle !important;
}

.btn-calculator .btn {
    padding: 1.5rem 1rem !important;
    font-size: 18px !important;
    font-family: sans-serif !important;
} 

.nav-link {
  cursor: pointer !important;
}

.header-button {
    padding-right: .8rem !important;
    padding-left: .8rem !important;
}

.nav-link img {
  height: 24px;
  width: 24px;
}
.rounded-circle {
  border-radius: 50% !important;
}

.user-text {
  padding-left:0.5rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

.dropdown-menu a:active, .dropdown-menu button:active {
  background-color: #fff !important;
}

.dropdown-menu a {
  color: #212529;
}
.dropdown-menu a:hover {
  color: #212529 !important;
}

.profile-dropdown svg {
  font-size: 17px;
  vertical-align: middle;
  margin-right: 5px;
  color: #adb5bd;
}

.text-danger svg {
  color: #ec536c !important;
}


/* ==============
  Header
===================*/
.navbar-brand {
  width: 205px !important;
  font-size:18px !important;
  margin-right: 0 !important;
}
.navbar-brand img {
  margin-right:5px;
}

/* ==============
  Buttons
===================*/
.btn-primary {
  background-color: #7a6fbe !important;
  border: 1px solid #7a6fbe !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
.btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover,
.open > .dropdown-toggle.btn-primary, .btn-outline-primary.active, .btn-outline-primary:active,
.show > .btn-outline-primary.dropdown-toggle, .btn-outline-primary:hover, .btn-primary.active,
.btn-primary:active, .show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  background-color: #6a5db6 !important;
  border: 1px solid #6a5db6 !important;
}

.btn-primary.focus, .btn-primary:focus, .btn-outline-primary.focus, .btn-outline-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(122, 111, 190, 0.3);
  box-shadow: 0 0 0 2px rgba(122, 111, 190, 0.3);
}

.btn-success {
  background-color: #58db83 !important;
  border: 1px solid #58db83 !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active,
.btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover,
.open > .dropdown-toggle.btn-success, .btn-outline-success.active, .btn-outline-success:active,
.show > .btn-outline-success.dropdown-toggle, .btn-outline-success:hover, .btn-success.active,
.btn-success:active, .show > .btn-success.dropdown-toggle,
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  background-color: #43d673 !important;
  border: 1px solid #43d673 !important;
}

.btn-success.focus, .btn-success:focus, .btn-outline-success.focus, .btn-outline-success:focus,
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(88, 219, 131, 0.3) !important;
  box-shadow: 0 0 0 2px rgba(88, 219, 131, 0.3) !important;
}

.btn-dark {
  background-color: #2a3142;
  border: 1px solid #2a3142;
  color: #ffffff;
}
.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus,
.btn-dark:active, .btn-dark:focus, .btn-dark:hover, .open > .dropdown-toggle.btn-dark,
.btn-outline-dark.active, .btn-outline-dark:active,
.show > .btn-outline-dark.dropdown-toggle, .btn-outline-dark:hover {
  background-color: #202532;
  border: 1px solid #202532;
  color: #ffffff;
}
.btn-dark.focus, .btn-dark:focus, .btn-outline-dark.focus, .btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(42, 49, 66, 0.3);
  box-shadow: 0 0 0 2px rgba(42, 49, 66, 0.3);
}

.text-danger {
  color: #ec536c !important;
}

/* ==============
  Cards
===================*/
.card {
  border: none !important;
  box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  margin-bottom: 30px;
}

.header-title {
  font-size: 16px;
}


/* ==============
  Inputs
===================*/
.form-control {
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* ==============
  Tables
===================*/
.no-borders th, .no-borders td {
  border-top:none !important;
  border-bottom:none !important;
}

.no-border-shadow {
  border-bottom: 1px solid #dee2e694;
  box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.pointer-tr tbody tr {
  cursor: pointer !important;
}

/* ==============
  Dropdown
===================*/
.dropdown-menu {
  padding: 4px 0;
  font-size: 13px !important;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.08) !important;
  background-color: #ffffff;
  border-color: transparent !important;
  margin-top: 8px !important;
}


/* ==============
  Sidebar
===================*/
.sidebar-wrapper {
    width: 220px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 56px;
    left: -300px;
    z-index: 999;
    background:#fff;  
    box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
    transition: .3s;
  }

  .sidebar-wrapper.show {
    left:0;
  }
  
  .sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-wrapper a {
    text-decoration: none;
  }

  .sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
  }
  
  .sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 15px 20px;
    display: inline-block;
  }
  
  .sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    font-size: 14px !important;
    text-decoration: none;
    position: relative;
    padding: 8px 30px 8px 20px;
  }
  
  .sidebar-wrapper .sidebar-menu ul li a svg {
    margin-right: 10px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
  }

/* ==============
  Modal
===================*/
.modal-title.h4 {
  font-size:18px;
}

.modal-content {
  border: none !important;
  box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
}

.modal-backdrop.show {
  opacity: .4 !important;
}


.bkg-primary {
  background-color: #7a6fbe !important;
}
.bkg-success {
  background-color: #58db83 !important;
}
.bkg-dark {
  background-color: #343a40 !important;
}

.mini-stat .mini-stat-img {
    background-image: url('../Images/bg-1.png');
    background-size: auto;
    background-size: cover;
}

.mini-stat .mini-stat-icon i {
    font-size: 30px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #ffffff !important;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: inline-block;
}

/* ==============
  Noty
===================*/
  .noty_layout { 
    position: absolute;
    bottom: 0;
    width: 100% !important;
    max-width: none !important;
    left:0 !important;
    font-size: 18px;    
  }

  .noty_bar {
    margin: 0 !important;
  }

  .noty_type__success {
    background-color: #4FC575 !important;
    border: 1px solid #4FC575 !important;
    color:#fff !important;
  }
  .noty_type__error {
    background-color: #ec536c !important;
    border: 1px solid #ec536c !important;
    color:#fff !important;
  }
  .noty_type__warning {
    background-color: #f5b225 !important;
    border: 1px solid #f5b225 !important;
    color:#fff !important;
  }
  .noty_type__info {
    background-color: #059cc6 !important;
    border: 1px solid #059cc6 !important;
    color:#fff !important;
  }

  .noty_progressbar {
    height: 4px !important;
    background-color: #646464 !important;
    opacity: 0.6 !important;
  }


#ticket_print {
    visibility: hidden;
    font-size:17px;
}

@media print {
    body * {
      visibility: hidden;
    }
  
    #ticket_print, #ticket_print * {
      visibility: visible;
      
    }

    #ticket_print {
        position: absolute;
        left: 0;
        top: 0;
    }
}


/* ==============
  Login
===================*/
.wrapper-page {
    margin: 5.5% auto;
    max-width: 460px;
    position: relative;
}

/* ==============
  Typography
===================*/
.font-14 {
    font-size:14px;
}

.spinner-border {
    margin-bottom: 0.8rem;
}

/* ==============
  Ticket
===================*/
.ticket {
    width: 100%;
    text-align: center;
    display: block;
    margin: 0 auto;
}
.ticket table {
    text-align: left;
}

.w-100 {
    width:100%;
}

.editable-quantity {
  text-align: center;
  background: none;
  border: none;
  width: 3rem;
}
.no-editable-quantity {
  display:inline-block;
  width: 3rem;
}

.text-proper {
  display:block;
  width:100%;
}

/* ==============
  Pagination
===================*/
.page-item.active .page-link {
  color: #fff !important;
  background-color: #6a5db6 !important;
  border: 1px solid #6a5db6 !important;
}